import { Menu } from '@/lib/model';
import { cn } from '@/lib/utils';
import { HTMLAttributes } from 'react';
import { SubpageMenuItem } from '../menu/SubpageMenuItem';
import { TRT_SECONDARY_MENU_HEADER } from '../constant';
import { getValidMenuItemsFromMenu } from '@/common/utils';

interface Props extends HTMLAttributes<HTMLDivElement> {
  menu?: Menu;
  withMarginBottom?: boolean;
  itemsWrapperClassName?: string;
}

export function SecondRowMenuWrapper({
  menu,
  className,
  withMarginBottom = true,
  itemsWrapperClassName,
  ...props
}: Props) {
  const marginBottomClasses = withMarginBottom
    ? 'mb-8 lg:mb-10 xl:mb-12 2xl:mb-16'
    : '';

  const validMenuItems = getValidMenuItemsFromMenu(menu);

  return (
    <>
      {/** there is a gap on sticky position onscroll, This is temporary solution to fill out the gap on sticky header sticked */}
      {validMenuItems && validMenuItems.length > 0 && (
        <div
          className={cn(
            'w-full flex sticky 2xl:top-header2xlH lg:top-headerLgH top-headerDefaultH z-[10] translate-y-[-1px] bg-grey-2 lg:justify-center justify-start items-center scrollbar-none overflow-scroll',
            'border-b border-b-grey-4 border-t-2 border-t-grey-4',
            className,
            marginBottomClasses,
          )}
          id={TRT_SECONDARY_MENU_HEADER}
          {...props}
        >
          <ul
            className={cn(
              'px-5 m-auto w-max flex overflow-x-auto gap-3.5 py-1.5 [html[lang="ur"]_&]:font-bold items-center',
              itemsWrapperClassName,
            )}
          >
            <SubpageMenuItem menuItems={validMenuItems} variant="secondary" />
          </ul>
        </div>
      )}
    </>
  );
}
